import starsBranch from '../Assets/images/svg/starsBranch.svg'
import excelsor from '../Assets/images/svg/excelsior.svg'
import forbes from '../Assets/images/svg/forbes.svg'
import techCrunch from '../Assets/images/svg/techchrunch.svg'
import expasion from '../Assets/images/svg/expansion.svg'
import starsBranchCL from '../Assets/images/webp/starsCL.webp'
import excelsorCL from '../Assets/images/webp/excelsiorCL.webp'
import forbesCL from '../Assets/images/webp/forbesCL.webp'
import techCrunchCL from '../Assets/images/webp/techcrunchCL.webp'
import expasionCL from '../Assets/images/webp/expansionCL.webp'
import lr from '../Assets/images/png/logo-lr.png'
import dinero from '../Assets/images/svg/mony.svg'
import ipro from '../Assets/images/svg/ipro.svg'

const data = {
  mx: [
    {
      id: 0,
      text: '“Adiós dolor, hola moons”',
      type: 'excelsor',
      image: excelsor
    },
    {
      id: 1,
      text: '“30 promesas del 2020…”',
      type: 'forbes',
      image: forbes
    },
    {
      id: 2,
      text: '',
      type: 'starsBranch',
      image: starsBranch
    },
    {
      id: 3,
      text: '“Ortodoncia invisible e impresión 3D …”',
      type: 'expasion',
      image: expasion
    },
    {
      id: 4,
      text: '“Mexican, Invisalign killer…”',
      type: 'techCrunch',
      image: techCrunch
    }
  ],
  co: [
    {
      id: 0,
      text: '““Tratamiento para todos””',
      type: 'lr',
      image: lr
    },
    {
      id: 1,
      text: '“Revoluciona la ortodoncia”',
      type: 'dinero',
      image: dinero
    },
    {
      id: 2,
      text: '',
      type: 'starsBranch',
      image: starsBranch
    },
    {
      id: 3,
      text: '“Observa los resultados antes de iniciar”',
      type: 'ipro',
      image: ipro
    },
    {
      id: 4,
      text: '“Ortodoncia invisible e impresión 3D …”',
      type: 'expasion',
      image: expasion
    }
  ],
  cl: [
    {
      id: 0,
      text: '',
      type: 'forbesCL',
      image: forbesCL
    },
    {
      id: 1,
      text: '',
      type: 'techCrunchCL',
      image: techCrunchCL
    },
    {
      id: 2,
      text: '',
      type: 'starsBranchCL',
      image: starsBranchCL
    },
    {
      id: 3,
      text: '',
      type: 'expasionCL',
      image: expasionCL
    },
    {
      id: 4,
      text: '',
      type: 'excelsorCL',
      image: excelsorCL
    }
  ]
}

export default data
