/* eslint-disable require-jsdoc */
/* eslint-disable complexity */
/* eslint-disable react/jsx-child-element-spacing */
import React from 'react'
import PropTypes from 'prop-types'
import buenfinLogo from '../../Assets/images/png/banner/buenfin-logo.png'
import bannerUno1x from '../../Assets/images/banner/bannerA.webp'
import bannerDos1x from '../../Assets/images/banner/bannerB.webp'
import bannerImgOne from '../../Assets/images/banner/bannerPink.png'
import bannerImgTwo from '../../Assets/images/banner/bannerYellow.png'
import bannerImgThree from '../../Assets/images/banner/bannerPurple.png'
import './Banner.css'

/**
 * Banner component
 * @author John Casas <john.casas@mymoons.co>
 * @version 1.0.0
 * @date 2021-09-22
 *
 * @param {{title: string}} - Title of the banner
 * @param {{date: string}} - Expiration date of the counter banner
 * @return {JSX.Element} Component
 */

/**
 * calculateTimeLeft
 * Calculate time left for the expiration date
 * @param {String} date expiration promo
 * @returns {Object} time left
 */
const calculateTimeLeft = (date) => {
  const difference = Number(new Date(date)) - Number(new Date())
  let timeLeft = {}

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    }
  }

  return timeLeft
}

/**
 * Banner
 * Component Banner
 * @param {String} title banner
 * @param {String} expiration date
 * @returns {Object} Component banner
 */

const Banner = ({
  title, date, buenFinPromo, country
}) => {
  const isChile = country === 'Chile' || country === 'chile'
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(date))
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(date))
    }, 1000)
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  })

  const promoImages = (direction) => {
    if (isChile) {
      return (
        <>
          <img
            src={direction ? bannerImgThree : bannerImgOne}
            alt="Moons"
            width={52}
            height={52}
          />
          <img
            src={bannerImgTwo}
            alt="Moons"
            width={52}
            height={52}
          />
          <img
            src={direction ? bannerImgOne : bannerImgThree}
            alt="Moons"
            width={52}
            height={52}
          />
        </>
      )
    }
    return (
      <>
        <img
          src={bannerUno1x}
          alt="Moons"
          width={45}
          height={52}
        />
        <img
          src={bannerDos1x}
          alt="Moons"
          width={60.39}
          height={52}
        />
      </>
    )
  }

  return (
    <div className="banner">
      {promoImages()}
      <div className="banner-content">
        {buenFinPromo ? (
          <img
            src={buenfinLogo}
            alt="Moons Buen fin"
            width={29}
            height={25}
            className="buenfinLogo"
          />
        ) : null}
        {buenFinPromo ? (
          <span className="buenfin--title">
            <b>¡</b>
            Aprovecha tu descuento de&nbsp;
            <b>
              $4,000 del 10 al 16 de noviembre
            </b>
             &nbsp;en el Buen Fin
            <b>!</b>
          </span>
        ) : (
          <span className="banner-title">{title}</span>
        )}
        {date && (
          <div className="timer-container">
            <span>
              {timeLeft.minutes ? timeLeft.minutes : '00'
              }
              m
            </span>
            <span className="separator">:</span>
            <span>
              {timeLeft.seconds ? timeLeft.seconds : '00'
              }
              s
            </span>
          </div>
        )}
      </div>
      {promoImages('reverse')}
    </div>
  )
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
}

Banner.defaultProps = {
  date: '',
}

export default Banner
