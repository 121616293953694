/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState } from 'react'
import {
  NEED_MEDICAL_TREATMENT, NEED_EXTRA_INFO, DEFAULT_CASE, PRADO_NAME
} from '../Utils/constants'
import {
  getPatientType, defineCurrency, getStringCurrency, buildDetailData
} from '../Utils/utils'

export const CustomerContext = React.createContext({
  customer: {},
  changeCustomer: () => {}
})

export default props => {
  const { children } = props
  const [customerInfo, setCustomerInfo] = useState({})

  /**
   * changeCustomer changes customer info in context
   * @param {Object} diagnostic Customer's data
   * @returns {JSX.Element} changes context
   */
  const changeCustomerInfo = async (diagnostic) => {
    const newDiagnostic = {}
    newDiagnostic.patientType = (diagnostic
      && getPatientType(diagnostic.Diagnostic_Status, diagnostic.Patient_Type))
      || DEFAULT_CASE
    newDiagnostic.alignersRange = (diagnostic && diagnostic.Aligners_Range) || ''
    newDiagnostic.appointmentStatus = (diagnostic && diagnostic.Appointment_Status) || ''
    newDiagnostic.patientName = diagnostic && diagnostic.Patient_Name
    if (newDiagnostic.patientType === NEED_MEDICAL_TREATMENT
      || newDiagnostic.patientType === NEED_EXTRA_INFO
    ) {
      newDiagnostic.reasons = (diagnostic && diagnostic.Diagnostic_Reasons) || []
    }
    newDiagnostic.modality = (diagnostic && diagnostic.Scan_Modality) || ''
    newDiagnostic.country = diagnostic && diagnostic.Country_Ops
    newDiagnostic.currency = (diagnostic && diagnostic.Currency
      && (defineCurrency(newDiagnostic.country) === diagnostic.Currency)
      && defineCurrency(newDiagnostic.country)
    ) || defineCurrency(newDiagnostic.country)
    newDiagnostic.paidAmount = getStringCurrency(
      (diagnostic && diagnostic.Paid_Amount) || 0, newDiagnostic.currency
    )
    newDiagnostic.discountCode = (diagnostic && diagnostic.Referral_Code) || ''
    newDiagnostic.detailData = await buildDetailData(
      newDiagnostic.patientType,
      diagnostic,
      newDiagnostic.currency
    )
    newDiagnostic.diagnosedBy = (diagnostic && diagnostic.Diagnosed_By) || PRADO_NAME
    newDiagnostic.paymentId = (diagnostic && diagnostic.PaymentId) || null
    setCustomerInfo(newDiagnostic)
  }
  return (
    <CustomerContext.Provider
      value={{ customer: customerInfo, changeCustomer: changeCustomerInfo }}
    >
      {children}
    </CustomerContext.Provider>
  )
}
