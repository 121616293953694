import { createRef } from 'react'
import {
  CURRENCY_COL, CURRENCY_MX, NEED_MEDICAL_TREATMENT, NO_CANDIDATE,
  NEED_EXTRA_INFO, MOONS_POSSIBLE_ADVANCED, PRODUCT_CLASS
} from './constants'
import Middleware from '../Api/Middleware'

/**
 * getStringCurrency transforms number currency to readable number currency string
 * @param {Number} amount of money
 * @param {String} currency  locale currency
 * @returns {String} currency amount in string
 */
export const getStringCurrency = (amount, currency) => {
  switch (currency) {
  case CURRENCY_COL:
    return Number(amount.toFixed(0)).toLocaleString('es-CO', { style: 'currency', currency })

  default:
    return Number(amount.toFixed(0)).toLocaleString('es-MX', { style: 'currency', currency })
  }
}

/**
 * DynamicRef Function
 * @param {String} total .
 * @return {JSX.Element}.
 */
export const dynamicRef = (total) => {
  const refs = []
  for (let initial = 0; initial < total; initial++) {
    refs[initial] = createRef()
  }
  return refs
}

/**
 * Get Style Item By Property
 * @param {String} node .
 * @param {String} property .
 * @return {JSX.Element}.
 */
export const getStyleItemByProperty = (node, property) => {
  const nodeStyle = window.getComputedStyle(node)
  const styles = parseInt(nodeStyle.getPropertyValue(property), 10)
  return styles
}

/**
 * defineCurrency if Patient has no currency in database, currency is defined by country
 * @param {String} country country to determine locale currency
 * @returns {String} currency corresponding to the country
 */
export const defineCurrency = (country) => {
  switch (country) {
  case 'Colombia':
  case 'colombia':
    return CURRENCY_COL

  default:
    return CURRENCY_MX
  }
}

/**
 * getPatientType define patient type for this application depending on
 * Diagnostic Status and Patient Type
 * @param {String} status Patient Diagnostic_Status from CRM
 * @param {String} type Patient Type from CRM
 * @returns {String} that will be a constant to be managed in this application
 */
export const getPatientType = (status, type) => {
  if (status) {
    if (status === NEED_MEDICAL_TREATMENT || status === NEED_EXTRA_INFO) {
      if (type === NO_CANDIDATE) {
        return type
      }
      return status
    }
    return type
  }
  return type
}

/**
 * fetchModerateComplexPrices async call to fetch specific product for prices
 * moons possible advanced case
 * @param {String} currency Customer's country
 * @param {Boolean} isComplex flag to get complex or moderate price
 * @returns {Number} price for moons complex o moderate by country
 */
export const fetchModerateComplexPrices = async (currency, isComplex) => {
  let requestBody = {}
  switch (currency) {
  case CURRENCY_COL:
    requestBody = {
      table: PRODUCT_CLASS,
      columns: {
        currency: CURRENCY_COL,
        category: 'moons',
        keyName: isComplex
          ? process.env.REACT_APP_PRODUCT_KEY_COMPLEX.replace('[country]', 'co')
          : process.env.REACT_APP_PRODUCT_KEY_MOD.replace('[country]', 'co')
      }
    }
    break

  default:
    requestBody = {
      table: PRODUCT_CLASS,
      columns: {
        currency: CURRENCY_MX,
        category: 'moons',
        keyName: isComplex
          ? process.env.REACT_APP_PRODUCT_KEY_COMPLEX.replace('[country]', 'mx')
          : process.env.REACT_APP_PRODUCT_KEY_MOD.replace('[country]', 'mx')
      }
    }
    break
  }
  const product = await Middleware.getProductPrice(requestBody)
  return (product && product.finalPrice) || 0
}

/**
 * buildDetailData object for CaseDetail component
 * (final price, complex/moderate price, doctor notes)
 * @param {String} patientType Customer's patient type
 * @param {Object} diagnostic object from service
 * @param {String} currency Customer's currency
 * @returns {Object} detail data object
 */
export const buildDetailData = async (patientType, diagnostic, currency) => {
  const detailData = {}
  if (patientType === NEED_MEDICAL_TREATMENT) {
    return detailData
  }
  if (patientType === MOONS_POSSIBLE_ADVANCED) {
    const modPrice = await fetchModerateComplexPrices(currency, false)
    detailData.moderatePrice = getStringCurrency(modPrice, currency)
    const complexPrice = await fetchModerateComplexPrices(currency, true)
    detailData.complexPrice = getStringCurrency(complexPrice, currency)
  }
  if (patientType === NO_CANDIDATE) {
    detailData.drNotes = (diagnostic && diagnostic.Diagnostic_Patient_Notes) || ''
  }
  detailData.finalPrice = getStringCurrency((diagnostic && diagnostic.Final_Price) || 0, currency)
  return detailData
}
