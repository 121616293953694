/* eslint-disable require-jsdoc */
import React, { useState } from 'react'
import style from './index.module.css'
import Mobile from './mobile/mobile'
import Tablet from './tablet/tablet'

const codesByCountry = (country) => {
  let countryTitles = ''
  switch (country) {
  case 'Colombia':
  case 'colombia':
    countryTitles = 'co'
    break
  case 'Chile':
  case 'chile':
    countryTitles = 'cl'
    break
  default:
    countryTitles = 'mx'
  }
  return countryTitles
}

/**
 * Slider Reviews Component
 * @return {JSX.Element}.
 */
const SliderReviews = ({ info, country }) => {
  const [countryCode] = useState(codesByCountry(country))
  return (
    <div className={style.CarouselContainer}>
      <Mobile info={info[countryCode]} />
      <Tablet info={info[countryCode]} />
    </div>
  )
}

export default SliderReviews
