/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable no-shadow */
/* eslint-disable no-undefined */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable require-jsdoc */
/* eslint-disable camelcase */
/* eslint-disable valid-jsdoc */
import React, { useContext } from 'react'
import styles from './PromoChileSection.module.css'
import { paramsObject } from '../../Utils/createUrlParams'
import { CustomerContext } from '../../context/context'

const queryParams = paramsObject()
const { Journey_Variant, scenterPromo } = queryParams

/**
 * promoChile component
 * @returns {JSX.Element} promoChile component
 */
const PromoChileSection = () => {
  const { customer } = useContext(CustomerContext)
  const {
    paymentId
  } = customer

  const promoChileVersion = () => (
    <div className={styles.freeChileContainer}>
      <p className={styles.freeChileText}>
      ¡Queremos verte sonreír! Aprovecha nuestra promo y agenda tu cita.
      </p>
      <p className={styles.freeChileTitle}>GRATIS</p>
    </div>
  )
  const redirectAppointment = (evt) => {
    evt.preventDefault()
    window.open(`${process.env.REACT_APP_BOOKING_MODULE_URL.replace('[domain]', 'cl')}${paymentId}?Journey_Variant=${Journey_Variant}&scenterPromo=${scenterPromo}`)
  }
  return (
    <div className={styles.promoChileContainer}>
      <p className={styles.promoChileTitle}>Agenda tu cita de escaneo sin costo</p>
      <div className={styles.promoChileContent}>
        <p className={styles.promoChileHeader}>PROMO CITA</p>
        <p className={styles.promoChileSubtitle}>Escaneo 3D</p>
        {promoChileVersion()}
        <button onClick={(evt) => redirectAppointment(evt)}>
            Agendar cita gratis
        </button>
      </div>
      <div className={styles.promoChileText}>
        <p className={styles.promoChileTitleMobile}>Agenda tu cita de escaneo sin costo</p>
        Durante tu cita, uno de nuestros especialistas realizará el escaneo 3D
        de tu sonrisa, el cual se utilizará para generar tu plan de tratamiento.
        Además, obtendrás una previsualización de tu nueva sonrisa.
      </div>
    </div>
  )
}

export default PromoChileSection
