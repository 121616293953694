const callToActionContent = {
  book: 'Agendar cita',
  contact: 'CONTACTAR',
  no_candidate: 'No soy candidato, quiero información sobre mi devolución',
  whatsapp: 'Hola, tengo dudas sobre el proceso Moons',
  need_medical_treatment: 'Para mi diagnóstico necesito tratamiento extra, quiero mas información',
  need_extra_info: 'Para mi diagnóstico necesitan más información, necesito más información',
  ctaBookText: 'Agenda tu cita ahora para comenzar tu tratamiento',
  candidate: 'AGENDAR MI CITA',
  candidate_co: 'PAGAR MI DEPÓSITO',
  doubts: '¿Aún tienes dudas? Envíanos un WhatsApp y un ejecutivo clínico las resolverá todas.',
  myDevolution: 'Envíanos un WhatsApp para recibir información sobre tu devolución.',
  contactUs: 'Contáctanos',
  notVideo: 'Navegador no soporta videos embebidos :(',
  disclaimer: '*Este es un prediagnóstico, lo confirmaremos en cita o en video llamada una vez que tengas tu kit',
  disclaimerCL: '*Los precios y tiempo de tratamiento promedio estimados, se basan en un estudio de mercado y un análisis de los distintos proveedores de alineadores invisibles y de ortodoncia tradicional nacional.'
}

export default callToActionContent
