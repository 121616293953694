/* eslint-disable require-jsdoc */
/* eslint-disable no-debugger */
import React from 'react'
import styles from './HiMoons.module.css'
import smile from '../../Assets/images/svg/smile.svg'
import pig from '../../Assets/images/svg/pig.svg'
import clock from '../../Assets/images/svg/fast-time.svg'
import { contentDefault, contentCL } from './HiMoons.content'

const titlesByCountry = (country) => {
  let countryTitles = ''
  switch (country) {
  case 'Chile':
  case 'chile':
    countryTitles = contentCL
    break
  default:
    countryTitles = contentDefault
  }
  return countryTitles
}

/**
 * HiMoons component UI to show the great properties of moons.
 * @returns {JSX.Element} A title with 3 sentences. Each one with images.
 */
const HiMoons = ({ country }) => {
  const [content] = React.useState(titlesByCountry(country))
  return (
    <div className={styles.Container}>
      <p className={styles.Title}>{content.title}</p>
      <div className={styles.ContainerContent}>
        <div className={styles.Statement}>
          <div className={styles.ImageWrapper}>
            <img
              src={smile}
              alt="smile icon"
            />
          </div>
          <p className={styles.TextStatement}>
            {content.first}
          </p>
        </div>
        <div className={styles.Statement}>
          <div className={styles.ImageWrapper}>
            <img
              src={pig}
              alt="save money icon"
            />
          </div>
          <p className={styles.TextStatement}>
            {content.second}
          </p>
        </div>
        <div className={styles.Statement}>
          <div className={styles.ImageWrapper}>
            <img
              src={clock}
              alt="fast time icon"
            />
          </div>
          <p className={styles.TextStatement}>
            {content.third}
          </p>
        </div>
      </div>
    </div>
  )
}
export default HiMoons
